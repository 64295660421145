import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import {
  Error,
  notEmptyArrayValidator,
  required,
  composeValidators,
  scrollSelect,
  sessionFieldsValidator,
  requiredSession,
} from "../FormService";
import FieldWrapper from "../../../shared/form-fields/FieldWrapper";
import { Link } from "gatsby";
import SessionField from "../../../shared/form-fields/fields/session/SessionField";

import ReservationSteps from "../reservation-steps/ReservationSteps";
import { RADIO_BOOLEAN_KEYS, FORM_KEY } from "../../../constants/constants";
import Radio from "../../../shared/form-fields/fields/radio/Radio";
import { useGlobalState } from "state-pool";

const ChooseTime = (props) => {
  const [isReservePage, setSsReservePage, updateIsReservePage] =
    useGlobalState("isReservePage");

  useEffect(() => {
    updateIsReservePage(function (isReservePage) {
      return true;
    });
  }, []);

  let fullProgramSuggestion = false;
  const parsedFormValues = JSON.parse(sessionStorage.getItem(FORM_KEY));
  const children = parsedFormValues.children;

  children.forEach((child) => {
    if (child.sessionType === "4-Days") {
      fullProgramSuggestion = true;
    }
  });

  return (
    <>
      <div id="initial-scroll-point-step-2"></div>
      <div className="d-flex flex-column form-page choose-time">
        <ReservationSteps step2="active" />
        <div id="error-scroll-point-step-2"></div>
        <div className="row mt-5">
          <h2 className="col-12 text-center">
            RESERVE YOUR SESSION: PICK YOUR MULTIPLE TIME SLOTS
          </h2>
        </div>
        <div className="row py-5">
          <div className="col-12 text-center">
            Registration Tip - The website IS NOT a live inventory. I encourage
            you to select as many possible swim times that work for you at each
            location. The more times you select in each dropdown, the greater
            the likelihood of attaining that location. MULTIPLE TIME SELECTIONS
            FOR EACH LOCATION ARE RECOMMENDED.
          </div>
        </div>
        <div className="fields-group-wrapper row mt-2">
          <div className="col-12">
            <div className="d-flex field-group row-cols-1">
              <FieldWrapper>
                <Error name={"sessions"} />
                <Field
                  name="sessions"
                  component={SessionField}
                  sessions={props.sessions}
                  validate={composeValidators(
                    requiredSession,
                    sessionFieldsValidator
                  )}
                ></Field>
              </FieldWrapper>
            </div>
          </div>
        </div>
        <div className="fields-group-wrapper row mt-4">
          <div className="col-12">
            <div className="d-flex field-group row-cols-1">
              <FieldWrapper>
                <label>
                  If there is an opportunity to host an 8-day immersion at your home (either half-day or full-day), would you like to be contacted? <span className="text-danger">*</span>
                </label>
                <div className="d-flex">
                  <Field
                    name={RADIO_BOOLEAN_KEYS.hostContacted}
                    component={Radio}
                    validate={required}
                    radioId={"yesContact"}
                    type={"radio"}
                    value="true"
                  >
                    Yes
                  </Field>
                  <div className="ms-3">
                    <Field
                      name={RADIO_BOOLEAN_KEYS.hostContacted}
                      component={Radio}
                      validate={required}
                      radioId={"noContact"}
                      type={"radio"}
                      value="false"
                    >
                      No
                    </Field>
                  </div>
                </div>
                <Error name={RADIO_BOOLEAN_KEYS.hostContacted} />
              </FieldWrapper>
            </div>
          </div>
        </div>
        {fullProgramSuggestion && (
          <div className="fields-group-wrapper row mt-4">
            <div className="col-12">
              <div className="d-flex field-group row-cols-1">
                <FieldWrapper>
                  <label>
                    If you selected the 4-Day 'Brush Up' Immersion and it is not
                    available, would you like to be considered for an 8-Day Swim
                    Immersion instead? <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex">
                    <Field
                      name={RADIO_BOOLEAN_KEYS.eightDayBrushup}
                      component={Radio}
                      validate={required}
                      radioId={"yesBrushup"}
                      type={"radio"}
                      value="true"
                    >
                      Yes
                    </Field>
                    <div className="ms-3">
                      <Field
                        name={RADIO_BOOLEAN_KEYS.eightDayBrushup}
                        component={Radio}
                        validate={required}
                        radioId={"noBrushup"}
                        type={"radio"}
                        value="false"
                      >
                        No
                      </Field>
                    </div>
                  </div>
                  <Error name={RADIO_BOOLEAN_KEYS.eightDayBrushup} />
                </FieldWrapper>
              </div>
            </div>
          </div>
        )}
        <div className="fields-group-wrapper row mt-4">
          <div className="col-12">
            <div className="d-flex field-group row-cols-1">
              <FieldWrapper>
                <label>
                  How did you hear about me? Please be as specific as possible:
                </label>
                <Field name="hearAboutMe" component={"textarea"}></Field>
              </FieldWrapper>
            </div>
          </div>
        </div>
        <div id="error-scroll-point-step-bottom-2"></div>
        <div className="fields-group-wrapper row mt-4">
          <div className="col-12">
            <div className="d-flex field-group row-cols-1">
              <FieldWrapper>
                <label>
                  Please describe in detail your child's current swimming
                  ability (enjoys water but does not like going under water or
                  in their eyes, good swimmer but needs strokes, only likes
                  being in the pool with floaties, will only swim with goggles,
                  total beginner, etc..). Please be as detailed as possible.
                  There are no wrong answers. This is just for my information.
                  <span className="text-danger">*</span>
                </label>
                <Field
                  name="skillLevel"
                  component={"textarea"}
                  validate={required}
                ></Field>
                <Error name="skillLevel" />
              </FieldWrapper>
            </div>
          </div>
        </div>

        <div className="fields-group-wrapper row mt-4">
          <div className="col-12">
            <div className="d-flex field-group row-cols-1">
              <FieldWrapper>
                <label>
                  Additional Comments / Medical & Health Concerns / Questions?
                  <br />
                  <span className="go-to-faq">
                    To see a list of Frequently Asked Questions, click{" "}
                    <a aria-label="faq link" href="/faq" target="_blank">
                      here
                    </a>
                  </span>
                </label>
                <Field name="comments" component={"textarea"}></Field>
              </FieldWrapper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChooseTime;

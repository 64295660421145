import React from "react";

import "./AgreementField.scss";
import {
  Error,
  required,
  requiredCheckbox,
  composeValidators,
  onlyLettersValidation,
  validateNamesMatch,
} from "../../../../components/reserve-form/FormService";
import { Field } from "react-final-form";

const AgreementCheckbox = ({ input, children, checkId }) => {
  return (
    <div className="form-check d-inline-block">
      <input
        className="form-check-input cursor-pointer picked"
        type="checkbox"
        {...input}
        id={checkId}
      />
      <label className="form-check-label cursor-pointer" htmlFor={checkId}>
        {children}
      </label>
    </div>
  );
};

const AgreementField = () => {
  var date = new Date();

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="agreement-text">
      In consideration of the enrolled participant(s) being allowed to
      participate in any way in Brian Lasky's swim lessons, its related events
      and activities, I,
      <Field
        name="parentName"
        // component="input"
        component={({ input, _ }) => (
            <input
                {...input}
                type="text"
                placeholder="Parent First &amp; Last Name"
                className="picked"
                onBlur={(event) => {
                    const trimmedValue = event.target.value?.trim();
                    input.onChange(trimmedValue);
                    input.onBlur(event);
                }}
            />
        )}
        type="text"
        validate={composeValidators(required, onlyLettersValidation)}
        placeholder="Parent First &amp; Last Name"
        className="picked"
      />
      <Error label="Parent/Guardian field" name="parentName" />, (the enrolled
      participant OR the parent/guardian of the participant if the participant
      is under the age of 18),the undersigned, acknowledge, appreciate, and
      agree that
      <ol className="mt-4">
        <li>
          The risk of injury from the activities involved the sport of swimming
          is significant, including the potential for permanent paralysis and
          death, and while particular skills, equipment, and personal discipline
          may reduce this risk, the risk of serious injury does exist; and,
        </li>
        <li>
          I KNOWINGLY AND FREELY ASSUME ALL SUCH RISKS, both known and unknown,
          EVEN IF ARISING FROM THE NEGLIGENCE OF THE RELEASEES or others, and
          assume full responsibility for my participation; and,
        </li>
        <li>
          I willingly agree to comply with the stated and customary terms and
          conditions for participation. If, however, I observe any unusual
          significant hazard during my presence or participation, I will remove
          myself from participation and bring such to the attention of Brian
          Lasky immediately; and,
        </li>
        <li>
          I, for myself and on behalf of my heirs, assigns, personal
          representatives and next of kin, HEREBY RELEASE, INDEMNIFY, AND HOLD
          HARMLESS Brian Lasky, his agents and/or employees, other participants,
          and/or if applicable, any owner(s), manager(s), lessor(s) or
          resident(s) of premises used for the swimming lessons, whether before,
          during or after a swim lesson ("Releasees"), WITH RESPECT TO ANY AND
          ALL INJURY, DISABILITY, DEATH, ILLNESS from any and all infectious
          disease, bacteria or viruses, or loss or damage to person or property,
          WHETHER ARISING FROM THE NEGLIGENCE OF THE RELEASEES OR OTHERWISE, to
          the fullest extent permitted by law.
        </li>
        <li>
          I also agree to indemnify Brian Lasky and/or any owner(s), manager(s)
          or resident(s) of the premises for any damages incurred arising from
          any attorneys fees, claims, demands, action or cause of action arising
          out of or relating to the enrolled participant(s) participation in
          Brian Lasky's swim lessons.
        </li>
        <li>
          I authorize Brian Lasky or any of his representatives to have the
          participant treated in any medical emergency during their
          participation in swim lessons with Brian Lasky. Further, I agree to
          pay all costs associated with medical care and transportation for the
          participant.
        </li>
        <li>
          I have noted on the reservation request form any medical/health
          problems of which Brian Lasky should be aware.
        </li>
      </ol>
      This is to certify that I, as parent/guardian with legal responsibility
      for this participant, do consent and agree to his/her release as provided
      above of all the Releasees, and, for myself, my heirs, assigns, and next
      of kin, I release and agree to indemnify and hold harmless the Releasees
      from any and all liabilities incident to my minor child's involvement or
      participation in these programs as provided above, EVEN IF ARISING FROM
      THE NEGLIGENCE OF THE RELEASEES, to the fullest extent permitted by law.
      <br />
      <br />
      <span>
        <Field
          name="agreementCheck"
          component={AgreementCheckbox}
          validate={requiredCheckbox}
          checkId="agr-check"
          type="checkbox"
        />
        <Error label="Acknowledgement " name="agreementCheck" />
      </span>
      <span style={{ fontWeight: "600" }}>
        I HAVE READ THIS RELEASE OF LIABILITY AND ASSUMPTION OF RISK AGREEMENT,
        FULLY UNDERSTAND ITS TERMS, UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL
        RIGHTS BY SIGNING IT, AND SIGN IT FREELY AND VOLUNTARILY WITHOUT ANY
        INDUCEMENT.
      </span>
      <br />
      <br />
      Date:{" "}
      {days[date.getDay()] +
        ", " +
        monthNames[date.getMonth()] +
        " " +
        date.getDate() +
        ", " +
        date.getFullYear()}
      <br />
      <br />
      <div>
        Parent/Guardian's name:
        <Field
          name="guardianName"
          // component="input"
          component={({ input, _ }) => (
              <input
                  {...input}
                  type="text"
                  placeholder="Parent First &amp; Last Name"
                  className="picked"
                  onBlur={(event) => {
                      const trimmedValue = event.target.value?.trim();
                      input.onChange(trimmedValue);
                      input.onBlur(event);
                  }}
              />
          )}
          type="text"
          validate={composeValidators(
            required,
            onlyLettersValidation,
            validateNamesMatch
          )}
          placeholder="Parent First &amp; Last Name"
          className="picked"
        />
        <Error label="Parent/Guardian confirmation" name="guardianName" />
      </div>
      <br />
      <div>
        Participant(s) name(s) :
        <Field
          name="childNames"
          component="input"
          type="text"
          validate={composeValidators(required, onlyLettersValidation)}
          placeholder="Child(s) Name(s)"
          className="picked"
        />
        <Error label="This field" name="childNames" />
      </div>
    </div>
  );
};
export default AgreementField;

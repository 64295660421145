import React, { useEffect } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import "./PersonalDetails.scss";

import {
  composeValidators,
  Error,
  phoneValidation,
  required,
  validateEmail,
  validateBirthday,
  scrollSelect,
  validateEmailMatch,
  onlyLettersValidation,
} from "../FormService";
import PhoneField from "../../../shared/form-fields/fields/phone/PhoneField";
import FieldWrapper from "../../../shared/form-fields/FieldWrapper";
import ReservationSteps from "../reservation-steps/ReservationSteps";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radio from "../../../shared/form-fields/fields/radio/Radio";
import { RADIO_BOOLEAN_KEYS } from "../../../constants/constants";
import DateField from "../../../shared/form-fields/fields/date/DateField";

const PersonalDetails = () => {
  return (
    <>
      <div className="d-flex flex-column form-page personal-details">
        <ReservationSteps step1="active" />
        <div className="row mt-5">
          <h2 className="col-12 text-center">
            RESERVE YOUR SESSION: STEP 1 OF 3
          </h2>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            Please provide the following steps in order to complete your
            request. Swim locations and times will be selected on the next page.
          </div>
        </div>
        <div className="row mt-4">
          <h2 className="col-12 text-center">PRICING:</h2>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            The cost per child is as follows:
          </div>
        </div>
        <div id="error-scroll-point"></div>
        <div className="programmes mt-2 row">
          <table className="col-12 px-3" role="presentation">
            <tbody>
              <tr>
                <td>
                  <div>8-Day Swim Immersion (All Levels; All Ages)</div>
                </td>
                <td>
                  <h2>$750 per child</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <div>4-Day 'Brush Up' Immersion (min. age 3.5 years)</div>
                </td>
                <td>
                  <h2>$450 per child</h2>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            Please make every effort to attend all lessons. Make-Up lessons are
            NOT guaranteed.
          </div>
        </div>
        <div className="row mt-4">
          <h2 className="col-12 text-center">GENERAL INFORMATION:</h2>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            Please submit your information so we can contact you with any
            questions we may have. We will not sell or abuse the information you
            provide.
          </div>
        </div>
        <div className="fields-group-wrapper row mt-2">
          <div id="error-phone-scroll-point"></div>
          <div className="col-12">
            <div className="d-flex field-group row">
              <div className="col-lg-4">
                <FieldWrapper>
                  <div id="error-email-verification-point"></div>
                  <label>
                    Parent's First Name:{" "}
                    <span className="text-danger">
                      <span className="text-danger">*</span>
                    </span>
                  </label>
                  <Field
                    name="firstname"
                    aria-label="firstname"
                    aria-required="true"
                    component="input"
                    type="text"
                    validate={composeValidators(
                      required,
                      onlyLettersValidation
                    )}
                  />
                  <Error label="First Name" name="firstname" />
                </FieldWrapper>
              </div>
              <div className="col-lg-4">
                <FieldWrapper>
                  <label>
                    Parent's Last Name: <span className="text-danger">*</span>
                  </label>
                  <Field
                    name="lastname"
                    aria-label="lastname"
                    aria-required="true"
                    component="input"
                    type="text"
                    validate={composeValidators(
                      required,
                      onlyLettersValidation
                    )}
                  />
                  <Error label="Last Name" name="lastname" />
                </FieldWrapper>
              </div>
              <div className="col-lg-4">
                <FieldWrapper>
                  <label>
                    E-mail Address: <span className="text-danger">*</span>
                  </label>
                  <Field
                    name="email"
                    aria-label="email"
                    aria-required="true"
                    component="input"
                    type="email"
                    validate={composeValidators(required, validateEmail)}
                  />
                  <Error label="E-mail" name="email" />
                </FieldWrapper>
              </div>
            </div>
            <div className="d-flex field-group row">
              <div className="col-lg-4">
                <FieldWrapper>
                  <label>Home Phone:</label>
                  <Field
                    name="homephone"
                    aria-label="homephone"
                    component={PhoneField}
                    validate={phoneValidation}
                  />
                  <Error name="homephone" />
                </FieldWrapper>
              </div>
              <div className="col-lg-4" name="phone-number">
                <FieldWrapper>
                  <label>
                    Cell Phone: <span className="text-danger">*</span>
                  </label>
                  <Field
                    name="cellphone"
                    aria-label="cellphone"
                    aria-required="true"
                    component={PhoneField}
                    validate={composeValidators(required, phoneValidation)}
                  />
                  <Error label="Cell Phone" name="cellphone" />
                </FieldWrapper>
              </div>
              <div className="col-lg-4">
                <FieldWrapper>
                  <label>
                    Verify E-mail Address:{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <Field
                    name="emailVerify"
                    aria-label="emailVerify"
                    component="input"
                    type="email"
                    aria-required="true"
                    validate={composeValidators(
                      required,
                      validateEmail,
                      validateEmailMatch
                    )}
                    autoComplete="new-value"
                    autoCorrect="some-value"
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                  <Error label="E-mail verification" name="emailVerify" />
                </FieldWrapper>
              </div>
            </div>
            <div className="d-flex field-group row">
              <div className="col-lg-12">
                <FieldWrapper>
                  <label>
                    Address: <span className="text-danger">*</span>
                  </label>
                  <Field
                    name="address"
                    aria-label="address"
                    aria-required="true"
                    component="input"
                    type="text"
                    validate={required}
                  />
                  <Error label="Address" name="address" />
                </FieldWrapper>
              </div>
            </div>
            <div className="d-flex field-group row">
              <div className="col-lg-4">
                <FieldWrapper>
                  <label>
                    City: <span className="text-danger">*</span>
                  </label>
                  <Field
                    name="city"
                    aria-label="city"
                    aria-required="true"
                    component="input"
                    type="text"
                    validate={required}
                  />
                  <Error label="City" name="city" />
                </FieldWrapper>
              </div>
              <div className="col-lg-4">
                <FieldWrapper>
                  <label>
                    State: <span className="text-danger">*</span>
                  </label>
                  <Field
                    name="state"
                    aria-label="state"
                    aria-required="true"
                    component="input"
                    type="text"
                    validate={required}
                  />
                  <Error label="State" name="state" />
                </FieldWrapper>
              </div>
              <div className="col-lg-4">
                <FieldWrapper>
                  <label>
                    Zip Code: <span className="text-danger">*</span>
                  </label>
                  <Field
                    name="zipcode"
                    aria-label="zipcode"
                    aria-required="true"
                    component="input"
                    type="text"
                    validate={required}
                  />
                  <Error label="Zipcode" name="zipcode" />
                </FieldWrapper>
              </div>
            </div>
            <div className="d-flex field-group row">
              <div className="col-lg-12">
                <FieldWrapper>
                  <label>
                    Have any of your children swam with me before?{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex">
                    <Field
                      name={RADIO_BOOLEAN_KEYS.swamWithMe}
                      component={Radio}
                      aria-required="true"
                      validate={required}
                      radioId={"yesSwam"}
                      type={"radio"}
                      value="true"
                    >
                      Yes
                    </Field>
                    <div className="ms-3">
                      <Field
                        name={RADIO_BOOLEAN_KEYS.swamWithMe}
                        component={Radio}
                        aria-required="true"
                        validate={required}
                        radioId={"noSwam"}
                        type={"radio"}
                        value="false"
                      >
                        No
                      </Field>
                    </div>
                  </div>
                  <Error name={RADIO_BOOLEAN_KEYS.swamWithMe} />
                </FieldWrapper>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex">
          <FieldArray name="children">
            {({ fields }) => (
              <div className="d-flex flex-column w-100">
                {fields.map((name, index) => (
                  <div
                    key={name}
                    className={`fields-group-wrapper row mt-4 child-inputs`}
                  >
                    <div className="col-12">
                      <div>
                        {index !== 0 && (
                          <div
                            className={`d-flex justify-content-end trash-icon-number${index}`}
                            onClick={() => fields.remove(index)}
                            style={{ cursor: "pointer", color: "#0070ba" }}
                          >
                            <FontAwesomeIcon
                              className="trash-can-icon"
                              icon={faTrashAlt}
                            />
                          </div>
                        )}
                        <div className="d-flex field-group row">
                          <div className="col-lg-4">
                            <FieldWrapper>
                              <label>
                                Student Name #{index + 1}:{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                name={`${name}.name`}
                                aria-label={`${name}.name`}
                                component="input"
                                aria-required="true"
                                validate={composeValidators(
                                  required,
                                  onlyLettersValidation
                                )}
                                className="child-input"
                              />
                              <Error label="Child Name" name={`${name}.name`} />
                            </FieldWrapper>
                          </div>
                          <div className="col-lg-4">
                            <FieldWrapper>
                              <label>
                                Birthday #{index + 1}:{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                name={`${name}.birthday`}
                                aria-label={`${name}.birthday`}
                                component="input"
                                aria-required="true"
                                className="child-input"
                                validate={composeValidators(
                                  required,
                                  validateBirthday
                                )}
                              />
                              <Error
                                label="Birthday"
                                name={`${name}.birthday`}
                              />
                            </FieldWrapper>
                          </div>
                          <div className="col-lg-4">
                            <FieldWrapper>
                              <label>
                                Returning Student:{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="d-flex">
                                <Field
                                  name={`${name}.${RADIO_BOOLEAN_KEYS.returningStudent}`}
                                  component={Radio}
                                  aria-label={`${name}.${RADIO_BOOLEAN_KEYS.returningStudent}`}
                                  validate={required}
                                  aria-required="true"
                                  radioId={`${name}.returningYes_${index}`}
                                  type={"radio"}
                                  value="true"
                                >
                                  Yes
                                </Field>
                                <div className="ms-3">
                                  <Field
                                    name={`${name}.${RADIO_BOOLEAN_KEYS.returningStudent}`}
                                    aria-label={`${name}.${RADIO_BOOLEAN_KEYS.returningStudent}`}
                                    component={Radio}
                                    aria-required="true"
                                    validate={required}
                                    radioId={`${name}.returningNo_${index}`}
                                    type={"radio"}
                                    value="false"
                                  >
                                    No
                                  </Field>
                                </div>
                              </div>
                              <Error
                                name={`${name}.${RADIO_BOOLEAN_KEYS.returningStudent}`}
                              />
                            </FieldWrapper>
                          </div>
                        </div>
                        <div className="d-flex field-group row">
                          <div className="col-lg-12">
                            <FieldWrapper>
                              <div className="row">
                                <label>
                                  Please select the type of session you would
                                  like the student to take.{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-12">
                                  <Field
                                    name={`${name}.sessionType`}
                                    aria-label={`${name}.sessionType`}
                                    component={Radio}
                                    aria-required="true"
                                    validate={required}
                                    radioId={`${name}.1_${index}`}
                                    type={"radio"}
                                    value="8-Days"
                                  >
                                    8-Day Swim Immersion (All Levels; All Ages)
                                    $750 per child
                                  </Field>
                                </div>
                                <div className="col-12">
                                  <Field
                                    name={`${name}.sessionType`}
                                    aria-label={`${name}.sessionType`}
                                    component={Radio}
                                    aria-required="true"
                                    validate={required}
                                    radioId={`${name}.2_${index}`}
                                    type={"radio"}
                                    value="4-Days"
                                  >
                                    4-Day 'Brush Up' Immersion (min. age 3.5
                                    years) - $450 per child
                                  </Field>
                                </div>
                                <Error
                                  label="Session Type"
                                  name={`${name}.sessionType`}
                                />
                              </div>
                            </FieldWrapper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="mx-auto add-child mt-4">
                  <button type="button" onClick={() => fields.push({})}>
                    Would you like to add another child? +
                  </button>
                </div>
              </div>
            )}
          </FieldArray>
        </div>
      </div>
    </>
  );
};
export default PersonalDetails;

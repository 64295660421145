import { Field } from "react-final-form";
import React, { useState } from "react";
import { FORM_KEY } from "../../constants/constants";

import { store, useGlobalState } from "state-pool";
import { scrollToTop } from "../../services/Utils";

const agreementStepErrors = {
  parentName: "Parent/Guardian field is required",
  agreementCheck: "Acknowledgement is required",
  guardianName: "Parent/Guardian confirmation is required",
  childNames: "This field is required",
};

export const Error = ({ name, label }) => {
  const [sessionError1, setSessionError1, updateSessionError1] =
    useGlobalState("sessionError1");

  const [sessionError2, setSessionError2, updateSessionError2] =
    useGlobalState("sessionError2");

  const [sessionError3, setSessionError3, updateSessionError3] =
    useGlobalState("sessionError3");

  let radio = false;
  if (
    name === "swamWithMe" ||
    name === "children[0].returningStudent" ||
    name === "children[0].sessionType"
  ) {
    radio = true;
  }

  let myEelems = document.getElementsByClassName("child-inputs");
  for (let i = 0; i < myEelems.length; ++i) {
    let eleChild = myEelems[i].getElementsByClassName("picked");
    if (eleChild.length > 0) {
      let trashIconElem = myEelems[i].getElementsByClassName("trash-can-icon");
      trashIconElem[0]?.classList.toggle("red-icon");
    }
  }

  return (
    <Field
      name={name}
      subscription={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) => {
        var phoneNumber = [
          document.querySelector(
            `div[name='phone-number'] input[name='first']`
          ),
          document.querySelector(
            `div[name='phone-number'] input[name='second']`
          ),
          document.querySelector(
            `div[name='phone-number'] input[name='third']`
          ),
        ];
        if (name === "cellphone") {
          phoneNumber.forEach((input) => {
            if (input?.classList.value.includes("picked")) {
              input?.classList.toggle("picked");
            }
          });
        }

        const changePhoneInputColor = () => {
          if (name.includes("phone")) {
            phoneNumber.forEach((input) => {
              input?.classList.toggle("picked");
            });
            scrollSelect("Cell Phone");
          }
        };

        var element = document.querySelector(`input[name='${name}']`);

        if (element?.classList.value.includes("picked")) {
          element?.classList.toggle("picked");
        }

        if (session1Entered) {
          updateSessionError1(function (sessionError1) {
            return false;
          });
        }

        if (session2Entered) {
          updateSessionError2(function (sessionError2) {
            return false;
          });
        }

        if (session3Entered) {
          updateSessionError3(function (sessionError3) {
            return false;
          });
        }

        if (touched && error) {
          if (error === "session1Triggered") {
            updateSessionError1(function (sessionError1) {
              return true;
            });
          }

          if (error === "session2Triggered") {
            updateSessionError2(function (sessionError2) {
              return true;
            });
          }

          if (error === "session3Triggered") {
            updateSessionError3(function (sessionError3) {
              return true;
            });
          }
        }

        if (
          touched &&
          error === "Required" &&
          label === "Parent/Guardian field"
        ) {
          setTimeout(() => {
            scrollToTop(240, "smooth");
          }, 500);
        }

        if (label && error === "Required") {
          return touched && error ? (
            <>
              {!radio && element?.classList.toggle("picked")}
              {changePhoneInputColor()}

              <span className={"validation-error mt-2"}>
                {label} is required
              </span>
              {scrollSelect(label)}
            </>
          ) : null;
        }

        if (
          Object.keys(agreementStepErrors).includes(name) &&
          error === undefined
        ) {
          return (
            !touched && (
              <span className={"validation-error mt-2"}>
                {agreementStepErrors[name]}
              </span>
            )
          );
        }

        return touched && error ? (
          <>
            {!radio && element?.classList.toggle("picked")}
            {changePhoneInputColor()}
            {name === "emailVerify" && scrollSelect("E-mail verification")}

            {!sessionError1 && !sessionError2 && !sessionError3 && (
              <span className={"validation-error mt-2"}>{error}</span>
            )}
            {name === "skillLevel"
              ? scrollSelect("error-scroll-point-step-bottom-2")
              : scrollSelect("Preference")}
          </>
        ) : null;
      }}
    />
  );
};

export const scrollSelect = (id) => {
  const scrollPointCases = [
    "First Name",
    "Last Name",
    "E-mail",
    "Address",
    "City",
    "State",
    "Zipcode",
  ];
  const scrollPointCasesStep2 = ["Preference"];
  const scrollPointCasesStep3 = ["This field "];
  let violation;
  if (scrollPointCases.includes(id)) {
    violation = document.getElementById("error-scroll-point");
  } else if (scrollPointCasesStep2.includes(id)) {
    violation = document.getElementById("error-scroll-point-step-2");
  } else if (scrollPointCasesStep3.includes(id)) {
    violation = document.getElementById("error-scroll-point-step-3");
  } else if (id === "initial-scroll-point-step-2") {
    violation = document.getElementById(id);
  } else if (id === "initial-scroll-point-step-1") {
    violation = document.getElementById(id);
  } else if (id === "initial-scroll-point-step-3") {
    violation = document.getElementById(id);
  } else if (id === "error-scroll-point-step-bottom-2") {
    violation = document.getElementById(id);
  } else if (id === "Cell Phone") {
    violation = document.getElementById("error-phone-scroll-point");
  } else if (id === "E-mail verification") {
    violation = document.getElementById("error-email-verification-point");
  }

  scrollToTop(violation?.offsetTop, "smooth");
};

export const required = (value) => {
  return value ? undefined : "Required";
};

export const requiredCheckbox = (value) => {
  let determinator;

  if (Array.isArray(value)) {
    determinator = value.length > 0 ? value : undefined;
  } else if (typeof value === "boolean") {
    determinator = value;
  } else {
    determinator = undefined;
  }

  if (!determinator) {
    return "Acknowledgement Required";
  } else {
    return undefined;
  }
};

export const requiredSession = (value) => {
  if (!value || value.length === 0) {
    scrollSelect("error-scroll-point-step-2");
  }
  return value && value.length !== 0
    ? undefined
    : "At least one preference is required";
};

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !re.test(String(email).toLowerCase()) ? "Invalid email" : "";
};

export const validateBirthday = (date) => {
  if (!date) {
    return "";
  }

  return !/^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/.test(
    date
  )
    ? "Please enter a date in the correct format"
    : "";
};

export const validateEmailMatch = (value) => {
  const emailField = document.getElementsByName("email");
  const emailFieldValue = emailField[0]?.value;
  if (value !== emailFieldValue) {
    return "Please make sure both emails are identical";
  } else {
    return undefined;
  }
};

export const validateNamesMatch = (value) => {
  const nameField = document.getElementsByName("parentName");
  const nameFieldValue = nameField[0]?.value;

  if (
    value &&
    nameFieldValue &&
    value.toLowerCase().trim() !== nameFieldValue.toLowerCase().trim()
  ) {
    return "Please make sure both parent/guardian names you specified are identical";
  } else {
    return undefined;
  }
};

export const agreementValidation = (value) => {
  return value ? undefined : "";
};

export const phoneValidation = (value) => {
  if (!value) {
    return "";
  }

  if (!/^\d{10}$/.test(value)) {
    return "Invalid phone format";
  } else if (value[0] === "0" || value[0] === "1") {
    return "The phone number should not start with 0 and 1";
  } else {
    return "";
  }
};

export const onlyLettersValidation = (value) => {
  if (!value) {
    return "";
  }

  return !/^[a-zA-Z\s]*$/.test(value)
    ? "This field should contain only letters"
    : "";
};

export const notEmptyArrayValidator = (value) => {
  return !(value && value.length) ? "At least one preference is required" : "";
};

var session1Entered = false;
var session2Entered = false;
var session3Entered = false;

export const sessionFieldsValidator = (value) => {
  if (!value) {
    return "";
  }

  const result = value.reduce((acc, item) => {
    acc[`session_${item.preference}`] = acc[`session_${item.preference}`] || [];
    acc[`session_${item.preference}`].push(item);
    return acc;
  }, {});

  if (result.session_1 && result.session_1[0].sessTimeId) {
    session1Entered = true;
  }
  if (result.session_2 && result.session_2[0].sessTimeId) {
    session2Entered = true;
  }
  if (result.session_3 && result.session_3[0].sessTimeId) {
    session3Entered = true;
  }

  let error = false;

  if (result.session_1 && !result.session_1[0].sessTimeId) {
    session1Entered = false;
    return "session1Triggered";
  }
  if (result.session_2 && !result.session_2[0].sessTimeId) {
    session2Entered = false;
    return "session2Triggered";
  }
  if (result.session_3 && !result.session_3[0].sessTimeId) {
    session3Entered = false;
    return "session3Triggered";
  }

  if (error) {
    scrollSelect("error-scroll-point-step-2");
  }
  return error
    ? "At least one choice per preference is required. If a preference no longer works for you, select -Clear- from the top of this dropdown menu, and leave blank."
    : "";
};

export const saveFormToStorage = (values) => {
  const res = { ...values };
  // delete res.sessions;
  sessionStorage.setItem(FORM_KEY, JSON.stringify(res));
};
